import './App.css';
import React from "react";
import { Route , Routes } from "react-router-dom";
import Homepage_CN from "./components/Homepage_CN";
import Homepage_EN from "./components/Homepage_EN";
import Homepage_JP from "./components/Homepage_JP";
import SchoolBusTime from "./components/SchoolBusTime"
import SchoolBusTimeJson from "./components/SchoolBusTime_JSON";

function App() {
  return (
    <div className="App">
        <Routes>
            {/* eslint-disable-next-line react/jsx-pascal-case */}w
            <Route path="/" element={<Homepage_CN />} />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Route path={"/EN"} element={<Homepage_EN />} />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Route path={'/JP'} element={<Homepage_JP />} />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Route path={'/SchoolBusTime'} element={<SchoolBusTime />} />
            <Route path={'/SchoolBusTimeJSON'} element={<SchoolBusTimeJson />} />
        </Routes>
    </div>
  );
}

export default App;
