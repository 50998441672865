import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export default function LanguagesSwitch({ defaultLang }) {
    const Languages_CN = useMemo(() => [
        { code: 'CN', label: '中文' },
        { code: 'GB', label: '英语' },
        { code: 'JP', label: '日语' },
    ], []);

    const Languages_GB = useMemo(() => [
        { code: 'CN', label: 'Chinese' },
        { code: 'GB', label: 'English' },
        { code: 'JP', label: 'Japanese' },
    ], []);

    const Languages_JP = useMemo(() => [
        { code: 'CN', label: '中国語' },
        { code: 'GB', label: '英語' },
        { code: 'JP', label: '日本語' },
    ], []);

    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [languages, setLanguages] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const defaultLanguage = (defaultLang === 'GB' ? Languages_GB : defaultLang === 'JP' ? Languages_JP : Languages_CN).find(lang => lang.code === defaultLang);
        setSelectedLanguage(defaultLanguage);
    }, [defaultLang, Languages_CN, Languages_GB, Languages_JP]);

    useEffect(() => {
        switch (selectedLanguage?.code) {
            case 'CN':
                setLanguages(Languages_CN);
                break;
            case 'GB':
                setLanguages(Languages_GB);
                break;
            case 'JP':
                setLanguages(Languages_JP);
                break;
            default:
                setLanguages(Languages_CN);
                break;
        }
    }, [selectedLanguage, Languages_CN, Languages_GB, Languages_JP]);

    const handleLanguageChange = (event, newValue) => {
        setSelectedLanguage(newValue);
        if (newValue?.code === 'GB') {
            navigate('/EN');
        }else if (newValue?.code === 'JP') {
            navigate('/JP');
        }else if (newValue?.code === 'CN') {
            navigate('/');
        }
    };

    return (
        <Autocomplete
            id="LanguagesSwitch"
            options={languages}
            autoHighlight
            getOptionLabel={(option) => option.label}
            value={selectedLanguage}
            onChange={handleLanguageChange}
            sx={{
                display: 'flex',
                width: '15%',
                paddingTop: '10px',
                paddingBottom: '10px',
            }}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                    />
                    {option.label} ({option.code})
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Choose a language"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    sx={{
                        backgroundColor: 'white',
                    }}
                />
            )}
        />
    );
}
