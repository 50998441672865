import React, {useEffect} from 'react';
import './Homepage.css';
import HomePageAppBar from "../modules/AppBar";
import {Container, Typography} from "@mui/material";

const Homepage = () => {
    useEffect(() => {
    document.title = "吴 昀罡";
  }, []);
    return (
        <>
            <HomePageAppBar />
            <Container>
                <Typography
                    variant="h6"
                    color={'black'}>
                    Coming soon...
                </Typography>
            </Container>
        </>
    );
}

export default Homepage;
