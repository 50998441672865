import React, {useEffect} from 'react';
import './Homepage.css';
import HomePageAppBar from "../modules/AppBar";
import {Container, Typography} from "@mui/material";

const Homepage_CN = () => {
    useEffect(() => {
    document.title = "吴 昀罡";
  }, []);
    return (
        <>
            <HomePageAppBar lang={'CN'}/>
            <Container>
                <Typography
                    variant="h6"
                    color={'black'}>
                    正在施工...
                </Typography>
            </Container>
        </>
    );
}

export default Homepage_CN;
